import { CheckCircleIcon } from '@heroicons/react/outline';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';
import Blurb from '~components/common/Blurb';
import Container from '~components/common/Container';
import CourseButton from '~components/common/CourseButton';
import Disclosure from '~components/common/Disclosure';
import FaqItem from '~components/common/FaqItem';
import FeatureList from '~components/common/FeatureList';
import GallerySlider from '~components/common/GallerySlider';
import GridThree from '~components/common/GridThree';
import GridTwo from '~components/common/GridTwo';
import ImageVideo from '~components/common/ImageVideo';
import TestmonialsSlider from '~components/common/TestmonialsSlider';
import Layout from '~components/layouts/Default';
import Cta from '~components/sections/Cta';
import PageHeader from '~components/sections/PageHeader';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';

function Page({ location, data }) {
  const features = [];
  data.kurse.features.forEach((feature) => {
    features.push({
      gray: feature.field.label === 'Ausgegraut (nicht verfügbar)' ? true : false,
      text: feature.value
    });
  });

  const faq = [
    {
      text: '<p>Das Autogene Training arbeitet mit Vorsatzformeln, die im Geiste wiederholt werden. Dabei fokussiert sich der Trainierende auf einzelne K&ouml;rperregionen und sagt sich die jeweiligen Zust&auml;nde mehrfach vor. So kann er sich sagen: &bdquo;Mein rechter Arm ist ganz schwer&hellip;&ldquo; bis die Muskulatur tats&auml;chlich an Spannung verliert und locker wird.</p>\n<p>Es wird mit der inneren Achtsamkeit gearbeitet, also der bewussten Lenkung der Aufmerksamkeit. Die sieben Grundformeln stehen dabei im Mittelpunkt. Sie bestehen aus Ruhe, Schwere, W&auml;rme, Atmung, Herz und Sonnengeflecht, sowie der Stirnk&uuml;hle. Das Verfahren ist besonders geeignet f&uuml;r Menschen, die eher in Ruhe entspannen.</p>',
      id: '6077ebf4528b725dca000e81',
      frage: 'Was ist Autogenes Training?'
    },
    {
      text: '<p>Das Autogene Training ist eines der zwei gro&szlig;en Entspannungsverfahren, die in Westeuropa am weitesten erforscht sind. Die positiven Auswirkungen sind wissenschaftlich belegt. Das andere Verfahren ist die Progressive Muskelentspannung. Auch mit dieser Methode machen &Auml;rzte, Psycho- aber auch Physiotherapeuten gute Fortschritte in der Arbeit mit ihren Patienten. Die Wirksamkeit ist daher unbestritten.&nbsp;</p>',
      id: '6077ec2083750949b741fc61',
      frage: 'Bringen solche Verfahren wirklich was?'
    },
    {
      text: '<p>Die beste Wirkung wird nat&uuml;rlich mit dem regelm&auml;&szlig;igen &uuml;ben eintreten. Es empfiehlt sich immer wieder dieselbe Zeit und denselben Ort einzunehmen. Wir empfehlen, zwei bis dreimal pro Woche zu &uuml;ben. Allerdings ist es auch nicht schlimm, wenn du es mal nicht schaffst zu trainieren. Daf&uuml;r hast du schlie&szlig;lich die begleiteten Kursstunden. Einmal richtig erlernt, kannst du es dein ganzes Leben lang nutzen.</p>',
      id: '6077ec2b922e814b790eac43',
      frage: 'Wie lange muss ich pro Tag üben?'
    },
    {
      text: '<p>Die Kurse Progressive Muskelentspannung &amp; Autogenes Training sind nach &sect;20 SGB V von der Zentralen Pr&uuml;fstelle der Pr&auml;vention zertifiziert und werden von den gesetzlichen Krankenkassen bezuschusst.</p>\n<p>Kurs-<strong>ID 20200807-1221034</strong>&nbsp;(Onlinekurs Autogenes Training) | Zentrale Pr&uuml;fstelle Pr&auml;vention.<br />Kurs-<strong>ID 20200625-12</strong><strong>9838</strong>&nbsp;(Onlinekurs Progressive Muskelentspannung) | Zentrale Pr&uuml;fstelle Pr&auml;vention.</p>',
      id: '6077ec468be6012a8800aef1',
      frage: 'Bezuschussen die Krankenkassen die Kurse?'
    },
    {
      text: '<p>Nein! Du kannst die Kurse buchen und die Teilnahmebescheinigung nach Abschluss bei der Krankenkasse einreichen. Die Bezuschussung von ca. 75&euro; &ndash; 150&euro; ist in der Regel innerhalb von 14 Tagen nach Abschluss des Kurses auf deinem Konto.</p>',
      id: '6077ec53afc98102f2507eb1',
      frage: 'Muss ich die Kurse vor Buchung bei der Krankenkasse anfragen?'
    },
    {
      text: '<p>Die Bezuschussung betr&auml;gt zwischen ca. 75&euro; &ndash; 150&euro; der Kosten. F&uuml;r eine genaue Aussage kontaktiere bitte deine Krankenkasse, da es hier individuelle Unterschiede gibt.</p>',
      id: '6077ec5d8be6012a8800aef3',
      frage: 'Wie hoch fällt die Krankenkassenförderung aus?'
    },
    {
      text: '<p>Kein Stress! Du kannst deinen Kurs insgesamt in Summe 4 Wochen aufschieben.</p>',
      id: '6077ec688be6012a8800aef5',
      frage: 'Was passiert , wenn ich krank  oder im Urlaub bin während des Kurses?'
    },
    {
      text: '<p>Du kannst die Kurse so oft buchen wie du m&ouml;chtest. Eine Krankenkassenf&ouml;rderung steht dir jedoch nur f&uuml;r zwei Kurse pro Kalenderjahr zur Verf&uuml;gung.</p>',
      id: '6077ec718be6012a8800aef7',
      frage: 'Wie viele Kurse darf ich Buchen?'
    }
    // {
    //   text: '<p>Autogenes Training ist hervorragend geeignet, um deinen Blutdruck zu normalisieren. Deswegen gibt es bei uns die Partnerschaft mit Blutdruckdaten.de. Nach dem Kauf des Kurses erh&auml;ltst du automatisch einen Gutscheincode f&uuml;r den Gratiszugang zur Premium Mitgliedschaft auf Blutdruckdaten.de. Somit hast du deine Blutdruckwerte bestens im Griff.</p>',
    //   id: '6077ec7a8be6012a8800aef9',
    //   frage: 'Autogenes Training Onlinekurs in Kooperation mit Blutdruckdaten.de'
    // }
  ];

  return (
    <Layout
      location={location}
      title="Autogenes Training Onlinekurs 14 Tage testen!"
      desc="Autogenes Training verbessert die Schlafqualität und Bluthochdruck. Teste den Onlinekurs jetzt für 14 Tage kostenfrei."
      active="Autogenes Training"
      green
    >
      <PageHeader title={data.kurse.title} image={data.bildKurs} classNameImage="max-w-md animate-shake-vertical" wrapperClass="max-w-5xl mx-auto">
        {data.kurse.desc && <P>{data.kurse.desc}</P>}
        <p className="font-display">
          <span className="text-5xl">{data.kurse.price} €</span>
          {/* space */}
          <span className="text-base"> /{data.kurse.frequenz} inkl USt.</span>
        </p>
        <div className="py-8">
          <h3 className="mb-3 font-display font-medium">Beinhaltet:</h3>
          <FeatureList items={features} className="space-y-5" />
        </div>
        <CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />
      </PageHeader>

      <Container>
        <H2 className="mb-16 text-center font-display">Warum Du das Autogene Training lieben wirst:</H2>
        <dl>
          <GridThree>
            <Blurb name="Direkter Nutzen für deine Gesundheit" description="Das regelmäßige Training unterstützt die Regulierung unzähliger deiner Körperfunktionen." icon={<CheckCircleIcon className="h-12 w-12" />} />
            <Blurb name="Besserer Schlaf" description="Grübeln oder Gedankenschleifen können mit dem Autogenen Training unterbrochen werden." icon={<CheckCircleIcon className="h-12 w-12" />} />
            <Blurb
              name="Einfach zu erlernen"
              description="Um das Entspannungsverfahren zu erlernen, kannst du gerne unseren achtwöchigen Onlinekurs buchen, oder eines unserer Liveseminare besuchen."
              icon={<CheckCircleIcon className="h-12 w-12" />}
            />
          </GridThree>
        </dl>
      </Container>
      <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />} />
      <Container>
        <GridTwo>
          <div className="relative grid grid-cols-1 gap-4 rounded-xl bg-white p-6 text-brand-gray md:grid-cols-1 lg:grid-cols-2">
            <div>
              <H2 className="mb-5 font-display">Was du hier lernst:</H2>
              <FeatureList
                items={[
                  { text: 'Was ist Autogenes Training?' },
                  { text: 'Positive Effekte des Autogenen Trainings' },
                  { text: 'Autogenes Training bei Bluthochdruck' },
                  { text: 'Besserer Schlaf durch Autogenes Training?' },
                  { text: 'So kannst du das Autogene Training lernen' },
                  { text: 'Autogenes Training: Eine Anleitung ' }
                ]}
                className="space-y-5"
              />
            </div>
            <div>
              <GatsbyImage image={getImage(data.bildJohannes)} alt="Autogenes Training - Dein Held Johannes" title="Autogenes Training - Dein Held Johannes" className="rounded-xl" />
            </div>
          </div>
          <div className="rounded-xl bg-white p-6 text-brand-gray">
            <H2 className="mb-5 font-display">Was unsere Absolventen sagen:</H2>
            <TestmonialsSlider />
          </div>
        </GridTwo>
      </Container>

      <Container noMargin className="text-brand-gray">
        <div className="mx-auto max-w-2xl space-y-8">
          <div>
            <H2 className="mb-5 font-display">Der Beweis das Autogenes Training funktioniert</H2>
            <ImageVideo
              video="https://res.cloudinary.com/lautenschlager/video/upload/v1642065910/kunden/entspannungshelden/Autogenes_Training_Beweis_r0wlue.mp4"
              image={data.videoCover}
              alt="Der Beweis dass Autogenes Training funktioniert"
            />
          </div>
          <div>
            <H2 className="mb-5 font-display">Was ist Autogenes Training?</H2>
            <P>
              Das Verfahren wurde 1930 vom deutschen Psychiater Johannes Heinrich Schultz entwickelt. Sein Fokus lag damals auf der Erforschung von Hypnose-Techniken und fand heraus, dass seine Patienten sich selbst in einen tranceartigen
              Zustand versetzen konnten, in dem sie ihre Wahrnehmung auf verschiedene Körperfunktionen lenkten. Diese aus sich selbst heraus erzeugte Entspannung, sollte fortan als Autogenes Training bekannt werden und wird seither im
              Leistungssport und der Therapie eingesetzt.
            </P>
          </div>

          <div>
            <H2 className="mb-5 font-display">Positive Effekte des Autogenen Trainings</H2>
            <P className="mb-3">
              Als Anwender des Autogenen Trainings hast du einen direkten Nutzen auf deine Gesundheit. Denn das regelmäßige Training unterstützt die Regulierung unzähliger deiner Körperfunktionen, wie Atmung, Blutdruck und Verdauung. Die
              regelmäßige Praxis hat für dich viele Vorteile:
            </P>
            <FeatureList
              items={[
                { text: 'Regulation deines arteriellen Blutdrucks' },
                { text: 'Reduktion von chronischen Schmerzen' },
                { text: 'Linderung psychosomatischer Beschwerden' },
                { text: 'Steigerung deiner Schlafqualität' },
                { text: 'Beruhigung deines Magen- und Darmtraktes' },
                { text: 'Mehr Gelassenheit und innere Ruhe' },
                { text: 'Steigerung deines Selbstwerts' },
                { text: 'Steigerung deiner Aufmerksamkeit und Konzentration' },
                { text: 'Abbau von Muskelverspannungen' },
                { text: 'Positive Wirkung auch bei psychischen Erkrankungen' }
              ]}
              className="grid grid-cols-1 gap-5 sm:grid-cols-2"
            />
          </div>
          <div>
            <H2 className="mb-5 font-display">Autogenes Training bei Bluthochdruck</H2>
            <P className="mb-3">
              Insbesondere bei Bluthochdruck ist die Wirkung des Autogenen Trainings extrem gut erforscht. Dass liegt daran, dass Patienten neben der (passiven) medikamentösen Behandlung, auch selbst Einfluss auf ihre Gesundheit nehmen
              möchten. Das Autogene Training wirkt mit seiner generalisierenden Entspannung auf den parasympathischen Teil des vegetativen Nervensystems, dass als Bremse verstanden werden kann und senkt deine Stressreaktionen, wie
              beispielsweise deinen erhöhten Blutdruck und flache Atmung.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Besserer Schlaf durch Autogenes Training?</H2>
            <P className="mb-3">
              Zudem ist das Autogene Training hervorragend für dich geeignet, wenn du unter Schlafproblemen leidest. Grübeln oder Gedankenschleifen können mit dem Autogenen Training unterbrochen werden, so dass es dir besser gelingt Ein-
              und durchzuschlafen. Da das Autogene Training immer mit einer schwebenden Aufmerksamkeit arbeitet bist du durch das gezielte Training dem Wunschzustand vom Schlaf sehr nah. Daher ist gerade der Zeitpunkt kurz vor dem zu Bett
              gehen ein guter Zeitpunkt zum entspannen.
            </P>
          </div>
          <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<CourseButton buttonText={`Kurs buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />} withoutContainer />
          <div>
            <H2 className="mb-5 font-display">So kannst du Autogenes Training lernen</H2>
            <P className="mb-3">
              Um das Entspannungsverfahren zu erlernen, kannst du gerne unseren achtwöchigen Onlinekurs buchen, oder eines unserer Liveseminare besuchen. Hierbei vermitteln wir die Grundstufe des Autogenen Trainings, bei der du die sieben
              Vorsatzformeln Schritt für Schritt erlernst. Neben der Grundstufe gibt es noch die Mittel- und Oberstufe. In der Grundstufe lernst du die sieben Formeln des Autogenen Trainings kennen und lenkst deine Wahrnehmung so ganz
              gezielt auf bestimmte Körperzustände, mit entspannender Wirkung. Am besten lernst du, wenn du kürzere Trainings, etwas häufiger absolvierst. Unsere Empfehlung lautet gerade für Anfänger: Übe täglich 2-3x für wenige Minuten,
              zum Beispiel mit der folgenden Anleitung.
            </P>
            <dl className="mb-3 space-y-3">
              <Disclosure title="Die Ruhetönung">
                <P className="mb-3">Zu Beginn des Trainings möchtest du ankommen und einen Rahmen für Ruhe und Entspannung schaffen, daher sorge für ein paar ungestörte Minuten.</P>
                <ol className="list-decimal space-y-3 pl-5 leading-relaxed text-brand-gray">
                  <li className="list-item pl-2">
                    Schalte dein Handy auf lautlos und setze oder lege dich in eine für dich bequeme Position. Du kannst hierfür gerne die Kutscherhaltung einnehmen. Deine Beine stehen am Boden, deine deine Unterarme liegen auf deinen
                    Oberschenkeln, dein Kopf hängt ganz entspannt zwischen deinen Schultern. Falls es dir möglich ist, lege dich gerne in die Rückenlage, die Arme liegen locker neben deinem Körper.
                  </li>
                  <li className="list-item pl-2">
                    Schließe deine Augen und konzentriere dich ganz auf deinen Atem und versuche ihn so natürlich wie möglich ﬂießen zu lassen. Du brauchst ihn nicht beeinﬂussen oder steuern. Lasse ihn einfach ein und ausströmen und
                    begleite ihn mit deiner Aufmerksamkeit.
                  </li>
                  <li className="list-item pl-2">
                    Vielleicht stellst du fest, dass die von dir gewählte Position noch einmal verändert werden muss, dann darfst du das gerne tun. Auch wenn deine Nase juckt, oder du dich am Kopf kratzen oder gähnen musst, gib dem einfach
                    nach.
                  </li>
                  <li className="list-item pl-2">
                    Beruhige nun deinen Geist durch das mehrfache Wiederholen der Formel „Ich bin ganz ruhig und entspannt.“ Nach 5-6 Wiederholungen sollte es dir gelungen sein, dass du lästige Gedankenkreise als nebensächlich wahrnimmst
                    und sie einfach vorbeiziehen lassen kannst.
                  </li>
                  <li className="list-item pl-2">Bist du ganz bei dir angekommen, kannst du dich auf deine Arme und Beine und auf die nächste Formel konzentrieren, die deinen Entspannungszustand noch weiter verstärkt.</li>
                </ol>
              </Disclosure>
              <Disclosure title="Die Schwereübung">
                <P className="mb-3">
                  Du lenkst deine Aufmerksamkeit auf deine Arme und Beine und stellst dir vor, dass diese wie angeklebt oder wie aus Stein auf der Matte liegen. Im Geiste wiederholst du für dich die Formel:{' '}
                  <i>„Meine Arme sind ganz schwer“</i>. Wiederhole die Formel 5-6x ganz in deinem Tempo und wechsle dann zu deinen Beinen über, indem du dir sagst: <i>„meine Beine sind ganz schwer“</i>. Gerne stellst du dir auch jetzt
                  wieder vor, dass deine Beine wie aus Stein am Boden liegen. Vielleicht gelingt es dir ja bereits die Schwere tatsächlich zu fühlen? Das liegt daran, dass sich deine Muskelspannung senkt und locker und weich wird. Dadurch
                  wird die Schwere tatsächlich erlebbar.
                </P>
              </Disclosure>
              <Disclosure title="Die Wärmeübung">
                <P className="mb-3">
                  Wie einen Strahl der Taschenlampe beleuchtest du nun zunächst deine Hände. Nach der Schwere deiner Arme und Beine konzentrierst du dich nun auf die Wärme deiner Hände und sagst dir zunächst 5-6x : „Meine Hände sind
                  angenehm warm“. Gehe dann über zu deinen Füßen und sage dir: „Meine Füße sind angenehm warm“. Mit etwas Training kannst du die Wärme schnell spüren. Beende dein Training dann, in dem du deine Hände und Füße bewegst, dich
                  streckst und deinem Körper genau die Bewegung gibst, nach der es dir gerade verlangt. Öﬀne im Anschluss deine Augen und komme wieder ganz an im hier und jetzt.
                </P>
              </Disclosure>
            </dl>
            <P>
              Neben den genannten Formeln lernst du in unserem achtwöchigem Kurs auch die anderen fünf Formeln des Autogenen Trainings kennen. Dabei nehmen wir dich Schritt für Schritt an die Hand und führen dich Wochenweise durch die
              einzelnen Übungen. Das geniale daran ist, dass du bestimmst wann und wo du dein Entspannungstrainings übst, denn mit unserem Kurs bist du zu 100% ﬂexibel. Audiodateien und ausführliche Handouts zu zahlreichen Gesundheitsthemen
              unterstützen dich bei deinem Übungserfolg und motivieren dich, wirklich dran zubleiben. Doch das beste kommt zum Schluss. Wir sind Qualitätsanbieter, daher wird ein Teil der Kursgebühr, von deiner Krankenkasse übernommen.
              Sichere dir deinen Gesundheitsbonus in Höhe von mindestens 75 Euro und starte mit uns durch! Wir freuen uns auf dich!{' '}
            </P>
          </div>
        </div>
      </Container>
      <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />} />
      <Container>
        <GridTwo>
          <div>
            <H2 className="mb-5 font-display">Impressionen aus dem Kurs:</H2>
            <GallerySlider images={data.gallery.nodes} totalcount={data.gallery.totalCount} alt="Autogenes Training Onlinekurs" />
          </div>
          <div>
            <H2 className="mb-5 font-display">Häufige Fragen zu Autogenes Training:</H2>
            <dl className="space-y-3">
              {faq.map((faq) => {
                return <FaqItem title={faq.frage} text={parse(faq.text)} key={faq.id} />;
              })}
            </dl>
          </div>
        </GridTwo>
      </Container>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  {
    bildKurs: file(relativePath: { eq: "course-images/autogenes-training.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    kurse(slug_slug: { eq: "autogenes-training-onlinekurs" }) {
      remoteKurseImage {
        url
        childImageSharp {
          gatsbyImageData
        }
      }
      frequenz
      price
      link
      title
      desc
      features {
        value
        field {
          label
        }
      }
    }

    bildJohannes: file(relativePath: { eq: "pages/kurse/autogenes-training/autogenes-training_dein-held-johannes.png" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }

    videoCover: file(relativePath: { eq: "video/autogenes-training-beweis_cover.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    gallery: allFile(filter: { relativeDirectory: { eq: "pages/kurse/autogenes-training/gallery" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.8)
        }
        id
      }
      totalCount
    }
  }
`;
